<div style="height:100%;display: flex; flex-direction: column;">
  <div>
    <app-nav-menu-top style="width: 100%;"></app-nav-menu-top>
  </div>
  <div style="height: 100%;">
    <ngx-spinner bdColor ="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple" [fullScreen]="true">
      <p style="color: white"> Loading...Please Wait</p></ngx-spinner>
      <router-outlet></router-outlet>
  </div>
</div>
