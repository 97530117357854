import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuardService {

  constructor(private tokenService: TokenService, private router: Router) { }

  canActivate(routerStateSnapshot: RouterStateSnapshot) {
    if (this.tokenService.isAuthenticated()) {
      if (!this.tokenService.isTokenExpired()) {
        return true;
      }
    }
    if (window.location.pathname.length > 1) {
      sessionStorage.setItem("initilaURL", window.location.pathname);
    }
    this.router.navigate(['/try-login'], { 
      queryParams: {
        returnUrl: routerStateSnapshot.url
      }
    });
    return false;
  }
}
