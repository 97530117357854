import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { TokenService } from '../services/token.service';
import { ExportType } from '../enums/exporttype.enum';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  constructor(private httpClient: HttpClient, private tokenService: TokenService) { }

  fetchData(action: string): Observable<any> {
    return this.httpClient.get<any>(environment.apiURL + action).pipe(
      map(data => data), catchError(this.errorHandler));
  }

  fetchDataWithBlob(action: string): Observable<any> {
    return this.httpClient.get(environment.apiURL + action, { responseType: 'blob' }).pipe(
      map(data => data), catchError(this.errorHandler));
  }

  add(action: string, data: any): Observable<any> {
    return this.httpClient
      .post(environment.apiURL + action, data).pipe(map(response => response), catchError(this.errorHandler));
  }

  update(action: string, data: any): Observable<any> {
    return this.httpClient
      .put(environment.apiURL + action, data).pipe(map(response => response), catchError(this.errorHandler));
  }

  delete(action: string, id: number): Observable<any> {
    return this.httpClient
      //.delete(`${environment.apiURL + action}/` + id).pipe(map(response => { return response; }), catchError(this.errorHandler));
      .delete(environment.apiURL + action + id).pipe(map(response => { return <any>response; }), catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.status === 400) {
      return throwError(JSON.stringify(error.error) || "Bad Request!");
    }
    return throwError(error.message || "Server Error");
  }

  public fetchDataWithHttpParams(action: string, params: HttpParams): Observable<any> {    
    return this.httpClient
      .get(environment.apiURL + action, { params: params }).pipe(map(res => res), catchError(this.errorHandler));
  }

  public GetResponseForDownload(url: string, params: HttpParams): Observable<Blob> {
    return this.httpClient
      .get(`${environment.apiURL + url}`, { responseType: 'blob', params: params });
  }

  public updateDataWithHttpPostParams(action: string, params: HttpParams) {
    return this.httpClient
      .post(environment.apiURL + action, params).pipe(map(response => response), catchError(this.errorHandler));
  }

  validateFunctionality(apiName: string): any {
    let permission = { CanWrite: false, CanRead: false, CanUpdate: false, CanDelete: false };
    let functionality = this.tokenService.getFunctionalities();
    if (functionality.includes(apiName)) {
      let functionalityArray = functionality.split(',');
      functionalityArray.forEach(functionalityValue => {
        if (functionalityValue.includes(apiName)) {
          let permissionArray = functionalityValue.split(' ');
          if (permissionArray[1].toLowerCase() == 'true') {
            permission.CanRead = true;
          }
          if (permissionArray[2].toLowerCase() == 'true') {
            permission.CanWrite = true;
          }
          if (permissionArray[3].toLowerCase() == 'true') {
            permission.CanUpdate = true;
          }
          if (permissionArray[4].toLowerCase() == 'true') {
            permission.CanDelete = true;
          }
        }
      });
    }
    return permission;
  }

  getAdUser(searchText: string): Observable<any> {
    return this.httpClient.get<any>(environment.apiURL + 'users/' + searchText).pipe(map(data => data), catchError(this.errorHandler));
  }

  applyFilter = (source: any[], filterText: string): any[] => {
    return source.filter(x => (x.userName + x.role.name).toLowerCase().indexOf(filterText.toLowerCase()) > -1);
  }

  getExportDetails(exportType: number, filterText: string, downloadZip: boolean) {
    var actionMethod: string;
    switch (exportType) {
      case ExportType.Role:
        if (downloadZip) {
          actionMethod = "Export/DownloadZip?filterText=" + filterText + "&&exportType=" + exportType;
        }
        else
          actionMethod = "Export/ExportRoles?filterText=" + filterText;
        break;
      case ExportType.UserRole:
        if (downloadZip) {
          actionMethod = "Export/DownloadZip?filterText=" + filterText + "&&exportType=" + exportType;
        }
        else
          actionMethod = "Export/ExportUserRoles/?filterText=" + filterText;
        break;
    }

    return this.httpClient
      .get(environment.apiURL + actionMethod, {
        withCredentials: true,
        responseType: 'arraybuffer',
        observe: 'response'
      }).pipe(map(data => data), catchError(this.errorHandler));
  }

  //MSTeams Notification
  private sendTeamsNotification(title: string, message: string) {
    const httpOptions = {
      headers: new HttpHeaders()
    };
    httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    httpOptions.headers.append('Access-Control-Allow-Methods', '*');
    httpOptions.headers.append('Access-Control-Allow-Headers', '*');
    httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');
    return this.httpClient.post(environment.NotificationAPIUrl, new Object({ Message: message, WebURLtoChannel: environment.NotificationHook, title: title }), httpOptions).subscribe((response: any) => {
    }, ((error: any) => {

    }));
  }
  
  public postAndGetResponseForDownload(url: string, params: HttpParams): Observable<Blob> {
    return this.httpClient.post(`${environment.apiURL + url}`
                                , params
                                , {responseType: 'blob',});
}
}
