// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverLoginUrl: location.origin + '/api/',
  whitelistedDomains: [],
  apiURL: location.origin + '/api/',
  environmentLabel: "Dev",
  NotificationHook: "https://outlook.office.com/webhook/412462fd-3cb6-494c-90c7-bef7f59ab936@d2d2794a-61cc-4823-9690-8e288fd554cc/IncomingWebhook/fc90c6eab8674a31aac4ef6bb59a6828/ecff2a8f-527f-48e8-852c-646d6d8c73aa",
  NotificationAPIUrl: "https://test-teamsapi.tetrapak.com/api/SendMessageToTeams"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
