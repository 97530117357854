<div class="modal" id="modalForm" tabindex="-1" role="dialog" [ngStyle]="{'display':showModal}" style="margin-top:60px;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center" style="background-color:rgb(99,138,179)">
        <h4 class="modal-title w-100 font-weight-bold" style="color:white;">Unauthorized Access!!!</h4>
      </div>
      <div class="modal-body mx-3" style="text-align:center">
        <p><strong>You do not have access to this Page.</strong></p>
        <br />
      </div>
    </div>
  </div>
</div>
