import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { DataService } from '../../data/services/data.service';

@Component({
  selector: 'app-exporttoexcel',
  templateUrl: './exporttoexcel.component.html',
  styleUrls: ['./exporttoexcel.component.css']
})
export class ExportToExcelComponent implements OnInit {
  constructor(private dataService: DataService) { }
  ngOnInit() {
  }

  @Input()
  exportType: number;
  @Input()
  filterText: string;

  downloadZip: boolean;

  @Output() onClick = new EventEmitter<any>();

  onExportClick() {
    this.dataService.getExportDetails(this.exportType, this.filterText, this.downloadZip).subscribe((response) => {
      let file = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      let fileName = response.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1];
      var a = document.createElement("a");
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      var url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = fileName;
      a.click();
    });
  }
}
