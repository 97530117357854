import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-APIDocument',
  templateUrl: './APIDocument.component.html',
  styleUrls: ['./APIDocument.component.css']
})
export class APIDocumentComponent implements OnInit {
  constructor() {}
  ngOnInit() {
    window.open(environment.serverLoginUrl + 'index.html', "");
  }
}
