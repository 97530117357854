import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TokenService } from './../data/services/token.service';

@Injectable({
  providedIn: 'root'
})
export class CommonToolHeaderService {

  private command = '';
  private projects: any;
  private toolObject: any;
  private auditObject: any;
  private customers: any;
  private isAudit = false;
  public isNavigatedFromProjectList: boolean;
  public toolSelectedFromToolsPage: number = 0;
  public currentComponent: any = null;
  public currentHeaderForm: FormGroup = null;  
  public isHidden: boolean = true;
  public isToolHeaderClicked: boolean = false;
  public isProjectHeaderClicked: boolean = false;

  constructor(private tokenService: TokenService) { }

  getCommand() {
    return this.command;
  }
  
  setComponent(value) {
    this.currentComponent = value;
  }
  getComponent() {
   return this.currentComponent;
  }
  setCommand(value) {
    this.command = value;
  }

  getProjects() {
    return this.projects;
  }

  setProjects(value) {
    this.projects = value;
  }

  getToolObject() {
    return this.toolObject;
  }

  setToolObject(value) {
    this.toolObject = value;
  }

  resetToolObject() {
    this.toolObject = '';
  }

  getAuditToolObject() {
    return this.auditObject;
  }

  setAuditToolObject(value) {
    this.auditObject = value;
  }
 

  getCustomers() {
    return this.customers;
  }

  setCustomers(value) {
    this.customers = value;
  }

  getCurrentUser() {
    return this.tokenService.getUserName();
  }

  setIsAudit(value) {
    this.isAudit = value
  }

  getIsAudit() {
    return this.isAudit;
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  public formatDateForPDF(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [month, day, year].join('/');
  }

  public generatePdfFileName(toolName: string, projectNumber: string, classNumber, sequenceNumber: string, revisionNumber: string, description: string) {
    var fileName =
      projectNumber + "-" +
      classNumber + "-" +
      sequenceNumber + "_" +
      revisionNumber + "_" +
      toolName +
      (description == null ? "" : "_" + description);
    return fileName;
  }
}
