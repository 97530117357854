import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../data/services/auth.service';

@Component({
  selector: 'app-try-login',
  templateUrl: './try-login.component.html',
  styleUrls: ['./try-login.component.css']
})
export class TryLoginComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService) { }

  ngOnInit() {
    
    const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
    this.authService.login(returnUrl);
  }

}
