import { Component, OnInit,AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../environments/environment';
import { AuthService } from './../../data/services/auth.service';
import { TokenService } from './../../data/services/token.service';
import { CommonToolHeaderService } from './../../shared/common-tool-header.service';

@Component({
  selector: 'app-nav-menu-top',
  templateUrl: './nav-menu-top.component.html',
  styleUrls: ['./nav-menu-top.component.css']
})
export class NavMenuTopComponent implements OnInit, AfterViewChecked{

  title: string;
  selectedTab:string = "Projects";
  isExpanded = false; // Add this line to declare the isExpanded property
  
  constructor(public tokenService: TokenService, protected router: Router, public authService: AuthService,  private spinnerService: NgxSpinnerService, public commonService: CommonToolHeaderService) {
  }

  ngOnInit() {
    this.title = 'Engineering Toolbox Azure ' + environment.environmentLabel;
  }

  ngAfterViewChecked() {
  }

  onLogout() {
    this.authService.logout();
    window.location.href = './../../../assets/logout.html';
  }

  goToNav(route: string) {    
      this.router.navigateByUrl(route);
  }
  
  onTabClicked(currentTab: string) {
    this.commonService.currentComponent?.onExit(true);
    this.selectedTab = currentTab;
    if (this.selectedTab == 'Projects') {
      this.commonService.isProjectHeaderClicked = true;
    }
    else {
      this.commonService.isProjectHeaderClicked = false;
    }
    if (this.selectedTab == 'Dashboard') {
      this.commonService.isToolHeaderClicked = true;
    }
    else {
      this.commonService.isToolHeaderClicked = false;
    }
   
    if (this.commonService.isHidden == true || location.pathname == "/home" || location.pathname == "/projects"      
    ) {
      if (this.selectedTab == 'Projects') {
        this.commonService.isProjectHeaderClicked = false;
        this.goToNav('/projects');
      }
      else if (this.selectedTab == 'Dashboard') {
        this.commonService.isToolHeaderClicked = false;
        this.goToNav('/home');
      }
    }
  }

}
