<div class="home-component-container">
  <div class="titleDiv">
    <div>Tools</div>
    <div>
      <input (keyup)="filterTools($event.target.value)" placeholder="Search here.." class="search-box" />
    </div>
  </div>

  <div class="main-card-container">
    <div class="main-card-container-row">
      
      <div class="card-item-header" [ngStyle]="{'display':showToolsNoResultsMessage}">
        <img src="../../../assets/images/warning-sign.svg" height="30" width="30" alt="warning" />
        <span class="no-results-span"><b>No results found!</b></span>
      </div>

      <div class="card-item" *ngFor="let tool of toolsObject;let ind=index;">
        <div class="card-item-header"><b>{{tool.toolMetaData.displayToolName}}</b></div>
        <div class="card-item-body">
          <p [innerHtml]="tool.description"></p>
          <a href="javascript:void(0);" (click)="readMoreLess(ind,tool.initialReadText)">{{tool.initialReadText}}</a>
          <span class="label" *ngIf="tool.usMetric">US + Metric</span>
        </div>
        <div class="card-item-footer">
          <button class="button-general view-projects-button" type="button"
            (click)="onRouteClick(tool.toolMetaData.listUrl)">
            View Projects
          </button>
          <button class="button-general open-tool-button" type="button" (click)="onToolQuickCalculation(tool.toolMetaData.addUrl)">
            Open Tool
          </button>
        </div>
      </div>
      <div class="card-item" *ngFor="let hostedTool of globalToolData;let ind=index;">
        <div class="card-item-header hosted-tool-card-item-header">
          <div>{{hostedTool.name}}</div>
          <img src="../../../assets/images/microsoft-excel.svg" width="25" height="25" alt="excel-icon">
        </div>
        <div class="card-item-body">
          <p *ngIf="hostedTool.description != 'null'" [innerHtml]="hostedTool.description"></p>
          <a href="javascript:void(0);" (click)="readMoreLessHostedTools(ind,hostedTool.initialReadText,globalToolData)">{{hostedTool.initialReadText}}</a>
        </div>
        <div class="card-item-footer" *ngIf="hostedTool.fileName">
          <button class="button-general download-tool-button" type="button" (click)="onDownloadClick(hostedTool.id)">
            Download Tool
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="titleDiv">
    <div>Local Tools</div>
  </div>
  <div class="main-card-container">
    <div class="main-card-container-row">

      <div class="card-item-header" [ngStyle]="{'display':showLocalToolsNoResultsMessage}">
        <img src="../../../assets/images/warning-sign.svg" height="30" width="30" alt="warning" />
        <span class="no-results-span"><b>No results found!</b></span>
      </div>

      <div class="card-item" *ngFor="let hostedTool of localToolData;let ind=index;">
        <div class="card-item-header hosted-tool-card-item-header">
          <div>{{hostedTool.name}}</div>
          <img src="../../../assets/images/microsoft-excel.svg" width="25" height="25" alt="excel-icon">
        </div>
        <div class="card-item-body">
          <p *ngIf="hostedTool.description != 'null'" [innerHtml]="hostedTool.description"></p>
          <a href="javascript:void(0);" (click)="readMoreLessHostedTools(ind,hostedTool.initialReadText,localToolData)">{{hostedTool.initialReadText}}</a>
        </div>
        <div class="card-item-footer" *ngIf="hostedTool.fileName">
          <button class="button-general download-tool-button" type="button" (click)="onDownloadClick(hostedTool.id)">
            Download Tool
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
