import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, NavigationEnd } from '@angular/router';
import { CommonToolHeaderService } from './../../shared/common-tool-header.service';
import { DataService } from '../../data/services/data.service';
import { ToastrService } from 'ngx-toastr';
import { ViewEncapsulation } from '@angular/core';
import { Tools, ToolIdentifier } from 'src/app/data/global/tools';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {

  public toolsObject: any[];
  public hostedToolData: any[];
  public globalToolData: any[];
  public localToolData: any[];

  public duplicateTools: any[];
  public duplicateHostedToolData: any[];
  public duplicateGlobalToolData: any[];
  public duplicateLocalToolData: any[];
  public showToolsNoResultsMessage: string = "none";
  public showLocalToolsNoResultsMessage: string = "none";

  mySubscription: any;
  readMoreLessText: string = 'Read More >';
  projectApi = 'projects/GetUserProjects';

  constructor(private toasterService: ToastrService,
    private dataService: DataService, private spinnerService: NgxSpinnerService, private router: Router,
    private commonService: CommonToolHeaderService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.spinnerService.show();
    this.commonService.resetToolObject();

    console.log("component", this.commonService.currentHeaderForm);

    this.toolsObject = [
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.AirVentSizing),
        description: "Purpose of this tool is to select adequate vent size for storage tank. <span class='dots'></span><span  class='more'> As storage tanks are designed to withstand only a small amount of internal pressure and not for vacuum. Therefore, to prevent this vacuum formation air must be allowed to enter into the tank and for this air in breathing an adequate vent must be provided on tank top.</span> ", initialReadText: "Read More >",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.FatBalanceCalculation),
        description: "This tool is used for calculating the fat and mass balance when processing raw milk into cream and standardized or skimmed milk. <span class='dots'></span><span  class='more'>Basically, the program solves two material balance equations to calculate two unknowns.</span>",
        initialReadText: "Read More >",
        usMetric:false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.OrificePlateSizing),
        description: "Purpose of this tool is to calculate the size of orifice diameter in order to achieve the required pressure drop on the downstream side of the orifice plate.",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.PipeSupportDesign),
        description: "Thermal expansion of ferrous pipework will be affected by forces & bending moments when restricted by supports & connections with equipment.<span class='dots'></span><span  class='more'> Therefore, the purpose of this tool is to design the piping support system to accommodate this thermal expansion.</span>",
        initialReadText: "Read More >",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.ProductVelocityInPipes),
        description: "Purpose of this tool is to calculate velocity through pipes for corresponding flow rate.",
        initialReadText: "",
        usMetric: true
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.PressureDropNonNewtonianFluid),
        description: "Purpose of this tool is to calculate pressure drop across piping system for non-Newtoneian fluids.<span class='dots'></span><span  class='more'> It uses power-law model which is applicable for time independent non-Newtonian fluids. A non- Newtonian fluid is one whose flow curve(shear stress versus shear rate) is non - linear or does not pass through the origin i.e.where the apparent viscosity(shear stress divided by shear rate) is not constant at a given temperature and pressure but is dependent on flow conditions such as flow geometry, shear rate etc.</span>", initialReadText: "Read More >",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.PressureDropNewtonianFluid),
        description: "Purpose of this tool is to calculate pressure drop across piping system for Newtonian fluids.",
        initialReadText: "",
        usMetric: true
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.RegulatingValveSizing),
        description: "Purpose of this tool is to calculate kv value and opening of the valve against selected standard kv value.",
        initialReadText: "",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.ConcentrateMixing),
        description: "Purpose of this tool is to calculate the quantities of concentrates and sugar syrup required to prepare final mixture.",
        initialReadText: "",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.ShuntCoupling),
        description: "Purpose of this tool is to calculate input flowrate to the system, for better pump selection.",
        initialReadText: "",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.StandardizationOfSkimAndCreamBalance),
        description: "Purpose of this tool is to calculate the amount of cream required to add/extract to standardize the milk.",
        initialReadText: "",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.HoldingCellCalculation),
        description: "Purpose of this tool is to calculate the length of the holding tube, Pressured drop across the holding tube and the velocity and Reynolds number.",
        initialReadText: "",
        usMetric: true
        

      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.RecombinedMilk),
        description: "Purpose of this tool is to calculate the quantities of Skim milk powder, cream and water/low fat milk to prepare milk of required fat and SNF content.",
        initialReadText: "",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.SteamValve),
        description: "Purpose of this tool is to calculate the amount of steam required to heat the media, selection of the steam regulating valve and steam trap based on the kv value.",
        initialReadText: "",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.SteamTrap),
        description: "Purpose of this tool is to select the steam trap to handle the quantity of steam required to heat the media.",
        initialReadText: "",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.IndirectBatchHeatingAndCooling),
        description: "Purpose of this tool is to calculate the time required to Heat/Cool the product in jacked tanks using Steam/Hot water/  Cold water.",
        initialReadText: "",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.FillingLineCalculation),
        description: "Filling Line Calculation Tool",
        initialReadText: "",
        usMetric: false
      },
      {
        toolMetaData: Tools.GetToolMetaData(ToolIdentifier.SteamTool),
        description: "Purpose of this tool is to calculate the steam flow required for heating and then size and select the steam regulating valve and steam trap accordingly.",
        initialReadText: "",
        usMetric: false
      },

    ];
    // this.hostedTools =
    // [
    //   {
    //     toolType: HostedToolType.ConsumptionLoadGraph,
    //     name: "Consumption Load Graph",
    //     downloadUrl: "/api/downloads?hostedToolType=" + HostedToolType[HostedToolType.ConsumptionLoadGraph]
    //   },
    //   {
    //     toolType: HostedToolType.PressureBalanceDiagram,
    //     name: "Pressure Balance Diagram",
    //     downloadUrl: "/api/downloads?hostedToolType=" + HostedToolType[HostedToolType.PressureBalanceDiagram]
    //   },
    //   {
    //     toolType: HostedToolType.Deaeration,
    //     name: "Deaeration",
    //     downloadUrl: "/api/downloads?hostedToolType=" + HostedToolType[HostedToolType.Deaeration]
    //   },
    // ];

    await this.GetHostedTool();

    this.duplicateTools = this.toolsObject.slice();
    this.toolsObject.sort(this.compare);
    this.filterTools("");
    this.spinnerService.hide();
  }

  async GetHostedTool() {
    var data = await this.dataService.fetchData('hostedtools').toPromise();
    this.hostedToolData = data.filter(r => r.isActive == true);

    let descriptionLengthLimit: number = 150;
    this.hostedToolData.forEach((item) => {
      if (!item.description) {
        return;
      }

      if (item.description.length > descriptionLengthLimit) {
        item.initialReadText = "Read More >";
        let temp: string = item.description;
        item.description = temp.slice(0, descriptionLengthLimit) +
          "<span class='dots-show'>...</span><span  class='more'>" +
          temp.slice(descriptionLengthLimit) +
          "</span>";
      }
      else {
        item.initialReadText = "";
      }
    });

    this.globalToolData = this.hostedToolData.filter(r => r.type == 'GlobalTool').sort(this.sortHostedTool);
    this.localToolData = this.hostedToolData.filter(r => r.type == 'LocalTool').sort(this.sortHostedTool);

    this.duplicateHostedToolData = this.hostedToolData.slice();
    this.duplicateGlobalToolData = this.globalToolData.slice();
    this.duplicateLocalToolData = this.localToolData.slice();
  }

  sortHostedTool = (a, b) => {
    return a.sortOrder - b.sortOrder;
  }

  compare(a, b) {
    const nameA = a.toolMetaData.displayToolName.toLowerCase();
    const nameB = b.toolMetaData.displayToolName.toLowerCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  onRouteClick(route) {
    this.spinnerService.show();
    this.commonService.toolSelectedFromToolsPage = 0;
    this.router.navigateByUrl(route);
  }

  onToolQuickCalculation(route) {
    this.spinnerService.show();

    this.dataService.fetchData(this.projectApi).subscribe(data => {
      this.commonService.setProjects(data);
      this.commonService.setCustomers(data.map(x => x.customer));
      this.commonService.setCommand('Add');
      this.commonService.toolSelectedFromToolsPage = 0;
      this.router.navigateByUrl(route);
    }, error => this.toasterService.error('Unable to fetch data.', 'Error!!'));

  }

  readMoreLess(index, text) {
    let discription = "";
    if (text == "") {
      return;
    }
    if (text == "Read More >") {
      discription = this.toolsObject[index].description.replace("more", "less");
      this.toolsObject[index].initialReadText = "< Read Less";
    }
    else {
      discription = this.toolsObject[index].description.replace("less", "more");
      this.toolsObject[index].initialReadText = "Read More >";
    }

    this.toolsObject[index].description = discription;
  }

  readMoreLessHostedTools(index, text, hostedToolsArray) {
    let discription = "";
    if (text == "") {
      return;
    }
    if (text == "Read More >") {
      discription = hostedToolsArray[index].description
        .replace("more", "less")
        .replace("dots-show", "dots-hide");
      hostedToolsArray[index].initialReadText = "< Read Less";
    }
    else {
      discription = hostedToolsArray[index].description
        .replace("less", "more")
        .replace("dots-hide", "dots-show");
      hostedToolsArray[index].initialReadText = "Read More >";
    }

    hostedToolsArray[index].description = discription;
  }

  filterTools(toolName) {
    if (toolName.trim() == "") {
      this.toolsObject = this.duplicateTools;
      //this.hostedToolData = this.duplicateHostedToolData;
      this.globalToolData = this.duplicateGlobalToolData;
      this.localToolData = this.duplicateLocalToolData
    }
    else {
      this.toolsObject = this.duplicateTools.filter(t => t.toolMetaData.displayToolName.toLowerCase().includes(toolName.toLowerCase()));
      //this.hostedTools = this.duplicateHostedTools.filter(t => t.name.toLowerCase().includes(toolName.toLowerCase()));
      this.globalToolData = this.duplicateGlobalToolData.filter(t => t.name.toLowerCase().includes(toolName.toLowerCase()));
      this.localToolData = this.duplicateLocalToolData.filter(t => t.name.toLowerCase().includes(toolName.toLowerCase()));
    }

    if (this.globalToolData.length == 0 && this.toolsObject.length == 0) {
      this.showToolsNoResultsMessage = "block";
    }
    else {
      this.showToolsNoResultsMessage = "none";
    }

    if (this.localToolData.length == 0) {
      this.showLocalToolsNoResultsMessage = "block";
    }
    else {
      this.showLocalToolsNoResultsMessage = "none";
    }
  }

  onDownloadClick(id: number) {
    var url = '/api/hostedtools/download/' + id;
    window.open(url, "_self");
  }
}
