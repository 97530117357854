import { Component, HostListener } from '@angular/core';
import { CommonToolHeaderService } from './shared/common-tool-header.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'ClientApp';
  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {
    this.commonToolHeaderService.currentComponent.onExit();
    if (this.commonToolHeaderService.isHidden == false || this.commonToolHeaderService.currentHeaderForm.dirty) {
      this.commonToolHeaderService.isHidden = true;
      $event.returnValue = false;
     
    }
  }
  constructor(public commonToolHeaderService: CommonToolHeaderService) {

  }
}
