<nav class="navbar navbar-expand-lg navbar-dark bg-dark bg-custom">
  <a class="navbar-brand" [routerLink]="['/']">
    <div class="logo-container">
      <img alt="Tetra Pak Logo" width="50" height="50" src="./../../../assets/images/STAMP_RGB_EN_regular.png" />
      <div class="logo-text">{{title}}</div>
    </div>
  </a>

  <button mat-icon-button class="navbar-toggler" type="button" aria-label="Toggle navigation" (click)="isExpanded = !isExpanded">  
    <mat-icon>menu</mat-icon>
  </button>

  <div class="collapse navbar-collapse nav-items-container" [ngClass]="{ 'show': isExpanded }" *ngIf="tokenService.getUserName()!=null">
    <ul class="navbar-nav">
      <li class="nav-item list-item-custom" [ngClass]="{'active-tab' : selectedTab == 'Projects'}">
        <a class="nav-link" (click)="onTabClicked('Projects')">Projects</a>
      </li>
      <li class="nav-item list-item-custom" [ngClass]="{'active-tab' : selectedTab == 'Dashboard'}">
        <a class="nav-link" (click)="onTabClicked('Dashboard')">Tools</a>
      </li>
    </ul>
  </div>
  <div class="collapse navbar-collapse nav-items-container" [ngClass]="{ 'show': isExpanded }">
    <ul class="navbar-nav">
      <li class="nav-item list-item-custom">
        <button mat-button onclick="window.open('./../../../assets/pdf/Reference Manual_Help File_V1.10.pdf', '_blank');" class="btn nav-button">Help File</button>
      </li>
      <li class="nav-item dropdown list-item-custom" *ngIf="tokenService.getUserName()!=null">    
        <button mat-button [matMenuTriggerFor]="menu" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle" style="color: white;">
          <img src="../../../assets/images/user-6781.svg" width="35" height="35" alt="profile-icon" class="user-profile-icon" />
          {{ tokenService.getUserName() }}
          <span class="mat-button-focus-overlay"></span>
        </button>
        <mat-menu  #menu="matMenu">         
          <button mat-menu-item (click)="goToNav('/admin/userrole')" *ngIf="tokenService.getRole() != null && tokenService.getRole() == 'Super User'">User Role Mapping</button>          
          <button mat-menu-item (click)="goToNav('/admin/hostedtool')" *ngIf="tokenService.getRole() != null && tokenService.getRole() == 'Super User'">Hosted Tool</button>          
          <button mat-menu-item (click)="onLogout()">Logout</button>
        </mat-menu>
      </li>    
    </ul>
  </div>
</nav>
