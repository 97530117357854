export interface IToolMetaData {
  displayToolName: string,
  apiToolName: string,
  addUrl: string,
  printUrl: string,
  listUrl: string,
  auditUrl: string
}

//When new ToolIdentifier enum is added, we also need to add the associated information in the Tools.MetaData
//The enum string value must exactly match with the name that is saved in the database.
export enum ToolIdentifier {
  AirVentSizing = 'Air Vent Sizing',
  FillingLineCalculation = 'Filling Line Calculation Tool', //formerly "Aseptic Line"
  ConcentrateMixing = 'Concentrate Mixing',
  FatBalanceCalculation = 'Fat Balance Calculation',
  HoldingCellCalculation = 'Holding Cell Calculation',
  IndirectBatchHeatingAndCooling = 'Indirect Batch Heating and Cooling',
  PressureDropNewtonianFluid = 'Pressure Drop_ Newtonian Fluids',
  PressureDropNonNewtonianFluid = 'Pressure Drop_Non Newtonian Fluids',
  OrificePlateSizing = 'Orifice Plate Sizing',
  PipeSupportDesign = 'Pipe Expansion Design', //formerly "Pipe Support Design",
  ProductVelocityInPipes = 'Product Velocity In Pipes',
  RecombinedMilk = 'Recombined Milk',
  RegulatingValveSizing = 'Regulating Valve Sizing Tool', //formerly "rv-p regulating valve sizing"
  ShuntCoupling = 'Shunt Coupling',
  StandardizationOfSkimAndCreamBalance = 'Standardization Of Skim and Cream Balance',
  SteamTrap = 'Steam Trap',
  SteamValve = 'Steam Valve',
  SteamTool = 'Steam Valve and Steam Trap Sizing',
}

export class Tools {
  public static GetToolMetaData(toolIdentifier: ToolIdentifier): IToolMetaData {
    return Tools.MetaData[toolIdentifier];
  }

  public static GetAllToolMetaData(): Array<IToolMetaData> {
    let allTools: Array<IToolMetaData> = [];

    for (let prop in Tools.MetaData) {
      let currentValue: IToolMetaData = Tools.MetaData[prop];
      allTools.push(currentValue);
    }

    return allTools;
  }

  //When new ToolType enum is added, we also need to add the associated information in the Tools.MetaData
  private static readonly MetaData: Record<ToolIdentifier, IToolMetaData> = {
    [ToolIdentifier.AirVentSizing]: {
      displayToolName: "Air Vent Sizing",
      apiToolName: ToolIdentifier.AirVentSizing,
      addUrl: "/avs/air-vent-sizing/",
      printUrl: "/avs/air-vent-sizing-print/",
      listUrl: "/avs/air-vent-sizing-list/",
      auditUrl: "/avs/air-vent-sizing-version/"
    },
    [ToolIdentifier.FillingLineCalculation]: {
      displayToolName: "Filling Line Calculation Tool",
      apiToolName: ToolIdentifier.FillingLineCalculation,
      addUrl: "/aseptic-transfer/operations/",
      printUrl: "/aseptic-transfer/view/",
      listUrl: "/aseptic-transfer/list/",
      auditUrl: "/aseptic-transfer/versions/"
    },
    [ToolIdentifier.ConcentrateMixing]: {
      displayToolName: "Concentrate Mixing",
      apiToolName: ToolIdentifier.ConcentrateMixing,
      addUrl: "/concentratemixing/add/",
      printUrl: "/concentratemixing/print/",
      listUrl: "/concentratemixing/",
      auditUrl: "/concentratemixing/revison/"
    },
    [ToolIdentifier.FatBalanceCalculation]: {
      displayToolName: "Fat Balance Calculation",
      apiToolName: ToolIdentifier.FatBalanceCalculation,
      addUrl: "/fatbalancecalculation/add/",
      printUrl: "/fatbalancecalculation/print/",
      listUrl: "/fatbalancecalculation/",
      auditUrl: "/fatbalancecalculation/revison/"
    },
    [ToolIdentifier.HoldingCellCalculation]: {
      displayToolName: "Holding Cell Calculation",
      apiToolName: ToolIdentifier.HoldingCellCalculation,
      addUrl: "/holding-cell/hc-operations/",
      printUrl: "/holding-cell/hc-view/",
      listUrl: "/holding-cell/hc-list/",
      auditUrl: "/holding-cell/hc-version-list/"
    },
    [ToolIdentifier.IndirectBatchHeatingAndCooling]: {
      displayToolName: "Indirect Batch Heating and Cooling",
      apiToolName: ToolIdentifier.IndirectBatchHeatingAndCooling,
      addUrl: "/ibhc/indirect-batch-heating-cooling/",
      printUrl: "/ibhc/ibhc-print/",
      listUrl: "/ibhc/ibhc-list/",
      auditUrl: "/ibhc/ibhc-audit-report/"
    },
    [ToolIdentifier.OrificePlateSizing]: {
      displayToolName: "Orifice Plate Sizing",
      apiToolName: ToolIdentifier.OrificePlateSizing,
      addUrl: "/ops/operations/",
      printUrl: "/ops/view/",
      listUrl: "/ops/list/",
      auditUrl: "/ops/previous-versions/"
    },
    [ToolIdentifier.PipeSupportDesign]: {
      displayToolName: "Pipe Expansion Design", //formerly "Pipe Support Design",
      apiToolName: ToolIdentifier.PipeSupportDesign,
      addUrl: "/psd/pipe-support-design/",
      printUrl: "/psd/pipe-support-design-print/",
      listUrl: "/psd/pipe-support-design-list/",
      auditUrl: "/psd/pipe-support-design-audit-report/"
    },
    [ToolIdentifier.PressureDropNewtonianFluid]: {
      displayToolName: "Pressure Drop_ Newtonian Fluids",
      apiToolName: ToolIdentifier.PressureDropNewtonianFluid,
      addUrl: "/newtonianfluid/add/",
      printUrl: "/newtonianfluid/print/",
      listUrl: "/newtonianfluid/",
      auditUrl: "/newtonianfluid/revison/"
    },
    [ToolIdentifier.PressureDropNonNewtonianFluid]: {
      displayToolName: "Pressure Drop_Non Newtonian Fluids",
      apiToolName: ToolIdentifier.PressureDropNonNewtonianFluid,
      addUrl: "/non-newtonian-pressure-drop/operations/",
      printUrl: "/non-newtonian-pressure-drop/view/",
      listUrl: "/non-newtonian-pressure-drop/list/",
      auditUrl: "/non-newtonian-pressure-drop/versions/"
    },
    [ToolIdentifier.ProductVelocityInPipes]: {
      displayToolName: "Product Velocity In Pipes",
      apiToolName: ToolIdentifier.ProductVelocityInPipes,
      addUrl: "/pvp/pvp-operations/",
      printUrl: "/pvp/pvp-print/",
      listUrl: "/pvp/pvp-list/",
      auditUrl: "/pvp/pvp-audit-report/"
    },
    [ToolIdentifier.RecombinedMilk]: {
      displayToolName: "Recombined Milk",
      apiToolName: ToolIdentifier.RecombinedMilk,
      addUrl: "/recombined-milk-calculations/operations/",
      printUrl: "/recombined-milk-calculations/view/",
      listUrl: "/recombined-milk-calculations/list/",
      auditUrl: "/recombined-milk-calculations/versions/"
    },
    [ToolIdentifier.RegulatingValveSizing]: {
      displayToolName: "Regulating Valve Sizing Tool",
      apiToolName: ToolIdentifier.RegulatingValveSizing,
      addUrl: "/rvs/regulating-valve-sizing/",
      printUrl: "/rvs/rvs-print/",
      listUrl: "/rvs/rvs-list/",
      auditUrl: "/rvs/rvs-audit-report/"
    },
    [ToolIdentifier.ShuntCoupling]: {
      displayToolName: "Shunt Coupling",
      apiToolName: ToolIdentifier.ShuntCoupling,
      addUrl: "/shuntcoupling/add/",
      printUrl: "/shuntcoupling/print/",
      listUrl: "/shuntcoupling/",
      auditUrl: "/shuntcoupling/revison/"
    },
    [ToolIdentifier.StandardizationOfSkimAndCreamBalance]: {
      displayToolName: "Standardization Of Skim and Cream Balance",
      apiToolName: ToolIdentifier.StandardizationOfSkimAndCreamBalance,
      addUrl: "/standardskimcream/add/",
      printUrl: "/standardskimcream/print/",
      listUrl: "/standardskimcream/",
      auditUrl: "/standardskimcream/revison/"
    },
    [ToolIdentifier.SteamTrap]: {
      displayToolName: "Steam Trap",
      apiToolName: ToolIdentifier.SteamTrap,
      addUrl: "/steam-trap/operations/",
      printUrl: "/steam-trap/view/",
      listUrl: "/steam-trap/list/",
      auditUrl: "/steam-trap/versions/"
    },
    [ToolIdentifier.SteamValve]: {
      displayToolName: "Steam Valve",
      apiToolName: ToolIdentifier.SteamValve,
      addUrl: "/steam-valve-calculations/operations/",
      printUrl: "/steam-valve-calculations/view/",
      listUrl: "/steam-valve-calculations/list/",
      auditUrl: "/steam-valve-calculations/versions/"
    },
    [ToolIdentifier.SteamTool]: {
      displayToolName: "Steam Valve and Steam Trap Sizing",
      apiToolName: ToolIdentifier.SteamTool,
      addUrl: "/steam-tool-calculations/operations/",
      printUrl: "/steam-tool-calculations/view/",
      listUrl: "/steam-tool-calculations/list/",
      auditUrl: "/steam-tool-calculations/versions/"
    }
  }
}
