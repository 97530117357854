import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { TokenService } from './token.service';
//import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateInterceptorService implements HttpInterceptor {

  constructor(private tokenService: TokenService, private tokenExtractor: HttpXsrfTokenExtractor) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === environment.serverLoginUrl) {
      return next.handle(req);
    }

    //const tokenReq = req.clone({
    //  headers: req.headers.set('Authorization', `Bearer ${this.tokenService.getToken()}`)
    //    .set('Timeout', `300000`)
    //});
    //return next.handle(tokenReq);

    const headerName = 'X-XSRF-TOKEN';

    let token = this.tokenExtractor.getToken() as string;
    token = this.getCookie("XSRF-TOKEN");

    if (token !== null && !req.headers.has(headerName)) {
      req = req.clone({ headers: req.headers.set(headerName, token) });
    }

    return next.handle(req);//.pipe(finalize(() => this.loadingscreenservice.stopLoading()));

  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

}
