import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = {};
  constructor(private httpClient: HttpClient,
    private router: Router,
    private tokenService: TokenService) { }

  login(returnUrl: string = '/') {
    var token = 'token';
    
    this.httpClient.get(environment.serverLoginUrl + token, { withCredentials: true, })
      .subscribe((tokenclass: TokenClass) => {
        
        this.tokenService.setToken(tokenclass.auth_token);
        this.router.navigateByUrl(sessionStorage.getItem("initilaURL"));
        sessionStorage.setItem("initilaURL", "");
      },
        ((error: any) => {
          if (error.status == 0) {
            window.location.href = location.origin + "/api/login";
          }
        })
      );
  }
  logout() {
    this.tokenService.removeToken();
    this.httpClient.post(environment.serverLoginUrl + 'login',null).subscribe((data) => {      
    });
  }
}
class TokenClass {
  auth_token: string;
}
