<div style="padding-bottom: 10px;padding-top: 10px;margin-left:15px;">
  <div class="row">
    <div>
      <button (click)="onExportClick()" class="btn btn-success">ExportToExcel</button>
      <input class="form-check-input" type="checkbox" value="" id="exportZip" style="margin-left:10px;height:25px;" [(ngModel)]="downloadZip">
      <label class="form-check-label" for="exportZip" style="margin-left:30px;">
        Download Zip
      </label>
    </div>
  </div>
</div>
