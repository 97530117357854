import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import 'chartjs-plugin-datalabels';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APIDocumentComponent } from './common-components/APIDocument/APIDocument.component';
import { TryLoginComponent } from './common-components/auth/try-login/try-login.component';
import { ErrorComponent } from './common-components/error/error.component';
import { ExportToExcelComponent } from './common-components/exporttoexcel/exporttoexcel.component';
import { HomeComponent } from './common-components/home/home.component';
import { NavMenuTopComponent } from './common-components/nav-menu-top/nav-menu-top.component';
import { PageNotFoundComponent } from './common-components/page-not-found/page-not-found.component';
import { AuthService } from './data/services/auth.service';
import { AuthenticateInterceptorService } from './data/services/authenticate-interceptor.service';
import { AuthenticatedGuardService } from './data/services/authenticated-guard.service';
import { DataService } from './data/services/data.service';
import { TokenService } from './data/services/token.service';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    AppComponent,
    TryLoginComponent,
    HomeComponent,
    NavMenuTopComponent,
    ExportToExcelComponent,
    ErrorComponent,
    PageNotFoundComponent,
    APIDocumentComponent,
  ],
  imports: [
    BrowserModule, RouterModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule,
    AppRoutingModule, HttpClientModule, JwtModule.forRoot({
      config: {
        tokenGetter: (getAccessToken),
        whitelistedDomains: environment.whitelistedDomains
      }
    }), BrowserModule, ToastrModule.forRoot({
      disableTimeOut: false,
      timeOut: 3000,
      extendedTimeOut: 3000,
      progressBar: true,
      closeButton: true,
      tapToDismiss: true,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      positionClass: 'toast-top-right'
    }),
    GridModule, ExcelModule, NgxSpinnerModule, ChartsModule, UploadsModule, MatSlideToggleModule
    , MatButtonModule, MatMenuModule
    , MatIconModule
  ],
  providers: [AuthenticatedGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticateInterceptorService,
      multi: true
    },
    TokenService,
    AuthService,
    DataService],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }

export function getAccessToken() {
  return sessionStorage.getItem('token');
}
