import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClaimType } from '../enums/claim.enum';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  tokenProperties: BehaviorSubject<any>;
  claimType = ClaimType;

  constructor(private jwtHelperService: JwtHelperService) {
    this.tokenProperties = new BehaviorSubject<any>(this.getCurrentProperties());
  }

  getToken(): string {
    return sessionStorage.getItem('token');
  }

  setToken(token: string) {
    sessionStorage.setItem('token', token);
    const currentProperties = this.getCurrentProperties();
    this.tokenProperties.next(currentProperties);
  }

  removeToken() {
    sessionStorage.removeItem('token');
    const currentProperties = this.getCurrentProperties();
    this.tokenProperties.next(currentProperties);
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token != null;
  }

  isTokenExpired() {
    if (this.tokenProperties.value === null || this.jwtHelperService.isTokenExpired()) {
      return true;
    } else {
      return false;
    }
  }

  tokenExpirationDate() {
    return this.jwtHelperService.getTokenExpirationDate(this.getToken());
  }

  private getCurrentProperties(): any {
    if (this.getToken() == null || this.getToken() === 'undefined') {
      return null;
    }

    return this.jwtHelperService.decodeToken();
  }


  getClaim(claim: ClaimType): string {
    if (!this.isAuthenticated || this.jwtHelperService.tokenGetter() === null) {
      return null;
    }
    const jwtPayload = this.jwtHelperService.decodeToken();
    return jwtPayload[this.claimType[claim]];
  }

  hasClaim(claim: ClaimType): boolean {
    return this.getClaim(claim) != null;
  }

  getUserName(): string {
    return this.getClaim(ClaimType.UserName);
  }
  getRole(): string {
    return this.getClaim(ClaimType.Role);
  }
  getFunctionalities(): string {
    return this.getClaim(ClaimType.UserFunctionalities)
  }
}
