import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APIDocumentComponent } from './common-components/APIDocument/APIDocument.component';
import { TryLoginComponent } from './common-components/auth/try-login/try-login.component';
import { ErrorComponent } from './common-components/error/error.component';
import { HomeComponent } from './common-components/home/home.component';
import { PageNotFoundComponent } from './common-components/page-not-found/page-not-found.component';
import { AuthenticatedGuardService } from './data/services/authenticated-guard.service';

const routes: Routes =
  [
    { path: '', loadChildren: () => import('./cust-porject-user-crud/cust-porject-user-crud.module').then(m => m.CustPorjectUserCrudModule) },
    { path: 'home', component: HomeComponent, canActivate: [AuthenticatedGuardService], runGuardsAndResolvers: 'always' },
    { path: 'try-login', component: TryLoginComponent },
    {
      path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
    {
      path: 'ops', loadChildren: () => import('./orifice-plate-sizing/orifice-plate-sizing.module').then(m => m.OrificePlateSizingModule)
    },
    {
      path: 'psd', loadChildren: () => import('./pipe-support-design/pipe-support-design.module').then(m => m.PipeSupportDesignModule)
    },
    {
      path: 'avs', loadChildren: () => import('./air-vent-sizing/air-vent-sizing.module').then(m => m.AirVentSizingModule)
    },
    {
      path: 'pvp', loadChildren: () => import('./product-velocity-pipe/product-velocity-pipe.module').then(m => m.ProductVelocityPipeModule)
    },
    {
      path: 'non-newtonian-pressure-drop', loadChildren: () => import('./non-newtonian-pressure-drop/non-newtonian-pressure-drop.module').then(m => m.NonNewtonianPressureDropModule)
    },
    {
      path: 'holding-cell', loadChildren: () => import('./holding-cell/holding-cell-module').then(m => m.HoldingCellModule)
    },
    {
      path: 'recombined-milk-calculations', loadChildren: () => import('./recombined-milk-calculations/recombined-milk-calculations.module').then(m => m.RecombinedMilkCalculationsModule)
    },
    { path: 'APIDocuments', component: APIDocumentComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'projects', loadChildren: () => import('./cust-porject-user-crud/cust-porject-user-crud.module').then(m => m.CustPorjectUserCrudModule) },
    { path: 'fatbalancecalculation', loadChildren: () => import('./fat-balance-calculation/fat-balance-calculation.module').then(m => m.FatBalanceCalculationModule) },
    { path: 'newtonianfluid', loadChildren: () => import('./newtonian-fluid/newtonian-fluid.module').then(m => m.NewtonianFluidModule) },
    {
      path: 'rvs', loadChildren: () => import('./regulating-valve-sizing/regulating-valve-sizing.module').then(m => m.RegulatingValveSizingModule)
    },
    {
      path: 'ibhc', loadChildren: () => import('./indirect-batch-heating-cooling/indirect-batch-heating-cooling.module').then(m => m.IndirectBatchHeatingCoolingModule)
    },
    { path: 'concentratemixing', loadChildren: () => import('./concentrate-mixing/concentrate-mixing.module').then(m => m.ConcentrateMixingModule) },
    { path: 'standardskimcream', loadChildren: () => import('./standardskimcream/standardskimcream.module').then(m => m.StandardskimcreamModule) },
    { path: 'shuntcoupling', loadChildren: () => import('./shunt-coupling/shunt-coupling.module').then(m => m.ShuntCouplingModule) },
    {
      path: 'steam-valve-calculations', loadChildren: () => import('./steam-valve-calculations/steam-valve-calculations.module').then(m => m.SteamValveCalculationsModule)
    },
    {
      path: 'steam-trap', loadChildren: () => import('./steam-trap/steam-trap-module').then(m => m.SteamTrapModule)
    },
    {
      path: 'aseptic-transfer', loadChildren: () => import('./aseptic-lines/aseptic-lines.module').then(m => m.AsepticLinesModule)
    },
    {
      path: 'steam-tool-calculations', loadChildren: () => import('./steam-tool/steam-tool-calculations.module').then(m => m.SteamToolCalculationsModule)
    },
    { path: '**', component: PageNotFoundComponent }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
